<template>
    <div>
        <div class="d-flex align-items-center mt-2 mb-1">
            <div v-if="showIncDec" class="d-flex input-number-box mr-2">
                <div class="dec button f-bold fz-18" @click="decQty()">-</div>
                <input type="text" :value=qty>
                <div class="inc button f-bold fz-18" @click="incQty()">+</div>
            </div>
            <button @click="addToCart"  class="btn btn-add-cart mr-0 w-100 justify-content-between">
                <span class="float-left">Añadir a carrito</span> <i class="fa fa-shopping-cart ml-0 float-right fz-18"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddToCartBtn",
    props:{
        article: Object,
        showIncDec: Boolean
    },
    data(){
        return {qty: 1}
    },
    mounted() {
        console.log('AddToCartBtn Component mounted.')
        console.log("showIncDec", this.showIncDec);
    },
    methods:{
        addToCart(){
            bus.$emit('added-to-cart',this.article, this.qty);
        },
        decQty(){
            if (this.qty > 1) {
                this.qty--;
            }
        },
        incQty(){
            this.qty++;
        },
    }
}
</script>

<style scoped>

</style>
