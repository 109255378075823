<template>
    <div>
        <span  class="d-inline-flex p-2" data-toggle="modal" data-target="#cartModal">
            <i class="fa fa-shopping-cart fz-24 color-blue" aria-hidden="true"></i>
            <span class="badge badge-danger ml-1">{{ cartCount }}0</span>
        </span>
    </div>
</template>

<script>
    export default {
        name: "CartCount",
        props:['cartCount'],

        data(){
            return {
            }
        },

        created() {
            console.log('CartCount Component mounted.')
        },
    }
</script>

<style scoped>

</style>
