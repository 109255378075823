<template>
    <div>
        <a @click="addToLayout" class="blue-link">Añadir a {{ this.cta() }}</a>
    </div>
</template>

<script>
export default {
    name: "AddToLayout",
    props: {
        layout: String,
        article: Number,
    },
    methods: {
        addToLayout() {
            const url = "/admin/addToLayout";
            axios.post(url,{
                layout: this.layout,
                article: this.article
            }).then(function (response) {
                if (response.status === 200) {
                    console.log("articleAddedToLayout status: " + response.status);
                    flash('Artículo añadido al layout', 'success');
                }
            }).catch(error => {
                flash('Error al añadir artículo al layout', 'error');
                console.log(error.message);
            });
        },
        cta() {
            if (this.layout === 'novelties')
                return 'Novedades'
            else if (this.layout === 'bestsellers')
                return 'Bestsellers'
            else
                return 'Recomendados'
        },
    }
}
</script>

<style scoped>

</style>
