/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

window.bus = new Vue();

/**
 * To use bootstrap-vue
 */
import Vue from 'vue'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

/**
 * To use Flash
 */
window.events = new Vue();
window.flash = function(message, type) {
    window.events.$emit('flash',message,type);
}

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('modal', require('./components/Modal.vue').default);
Vue.component('cart-count', require('./components/CartCount.vue').default);
Vue.component('cart-detail', require('./components/CartDetail.vue').default);
Vue.component('add-to-cart-btn', require('./components/AddToCartBtn').default);
Vue.component('add-to-layout', require('./components/AddToLayout').default);
Vue.component('flash', require('./components/Flash.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    data: {
        cart: []
    },
    created(){
        this.getCart();

        bus.$on('added-to-cart', (product, qty) => {
            this.addToCart(product, qty);
        });

        bus.$on('remove-from-cart', (product,all) => {
            this.removeFromCart(product,all);
        });

        bus.$on('send-cart', () => {
            this.sendCart();
        });

        bus.$on('article-added-to-layout', () => {
            this.articleAddedToLayout();
        });

    },
    computed: {
        cartTotal(){
            return this.cart.reduce((total, product) => {
                return total += product.qty * (product.has_discount ?  product.pvp - (product.pvp * (product.discount_percent/100)) : product.pvp);
            }, 0);
        },
        totalItems(){
            return this.cart.reduce((total, product) => {
                return total + product.qty;
            }, 0);
        }
    },
    methods: {
        getCart () {
            if (localStorage && localStorage.getItem('cart')) {
                this.cart = JSON.parse(localStorage.getItem('cart'));

            } else {
                this.cart = [];
            }
        },
        addToCart(product, qty=1){
            console.log('En add to cart de app.js')
            const matchingProductIndex = this.cart.findIndex((item) => {
                return item.id === product.id;
            });

            if (matchingProductIndex > -1) {
                this.cart[matchingProductIndex].qty += qty;
            } else {
                product.qty = qty;
                this.cart.push(product);

            }
            localStorage.setItem('cart', JSON.stringify(this.cart));
            flash('Producto añadido al carrito', 'success');
        },

        removeFromCart(product, all=false){
            const matchingProductIndex = this.cart.findIndex((item) => {
                return item.id == product.id;
            });

            if (this.cart[matchingProductIndex].qty <= 1 || all) {
                this.cart.splice(matchingProductIndex, 1);
            } else {
                this.cart[matchingProductIndex].qty--;
            }

            localStorage.setItem('cart', JSON.stringify(this.cart));
        },

        sendCart() {
            var url = "/storeCart";
            axios.post(url,{
                cart: this.cart
            }).then(function (response){
                console.log(response)
                window.location = "/checkout";
            }).catch(error => {
                console.log(error.message);
            });
        },

        articleAddedToLayout() {
            flash('Artículo añadido al layout', 'success');
        }
    }
});
