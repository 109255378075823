<template>
    <div>
        <span  class="d-flex align-items-end pt-1 ml-2" data-toggle="modal" :data-target="'#'+modalid">
<!--            <i class="fa fa-shopping-cart fz-24 color-blue" aria-hidden="true"></i>-->
<!--            <span class="badge badge-danger ml-1">{{ totalitems }}</span>-->
            <img class="icon-cart" src="/img/icons/icon-cart.png">
            <span class="cart-items-number">{{ totalitems }}</span>
            <span class="fz-10 color-blue cart-text d-none d-md-block">Carrito</span>
        </span>

        <!-- Modal -->
        <div class="modal left fade cartModal" ref="cartModal" :id="modalid" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header d-flex align-items-center">
                        <h3 class="color-blue mb-0">Mi cesta</h3>
                        <button type="button" class="close color-blue" data-dismiss="modal">
                            <img class="icon-close-modal" src="/img/icons/close-blue.png">
                        </button>
                    </div>

                    <div class="bg-blue p-3">
                        <div class="d-flex w-100 align-items-center">
                            <div class="color-white">{{ totalitems}} Artículos</div>
                            <div class="color-white ml-auto fz-24"><strong>{{carttotal.toFixed(2).replace('.', ',')}} €</strong></div>
                        </div>
                        <div class="mt-2">
                            <button class="btn btn-add-cart w-100" @click="sendCart">Ir a pagar</button>
                        </div>
                    </div>

                    <div class="modal-body scroll-x">
                        <div v-for="cartItem in cart" class="row mt-2 mb-2">
                            <div class="col-12 mb-4">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="img-box">
                                            <img class="img-fluid" :src="cartItem.coverUrl">
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="row" style="height: 90px;">
                                            <div class="col-9">
                                                <div class="fz-15">{{cartItem.description}}</div>
                                                <div class="fz-15">{{cartItem.author}}</div>
                                            </div>
                                            <div class="col-3">
                                                <div class="float-right">
                                                    <button type="button" class="close color-blue" @click="removeFromCart(cartItem,true)">&times;</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-9">
                                                <div class="d-flex input-number-box mr-1">
                                                    <div class="dec button f-bold fz-18" @click="removeFromCart(cartItem)">-</div>
                                                    <input type="text" :value=cartItem.qty>
                                                    <div class="inc button f-bold fz-18" @click="addToCart(cartItem)">+</div>
                                                </div>
                                            </div>
                                            <div class="col-3 pl-0">
                                                <div class="float-right">
                                                    <div v-if="cartItem.has_discount"><s class="color-orange fz-10 float-right">{{ (cartItem.pvp).toFixed(2).replace('.', ',') }}€</s>
                                                        <div class="fz-15 f-bold">{{ discountedPrice(cartItem.pvp, cartItem.discount_percent) }} €</div>
                                                    </div>
                                                    <div v-else>
                                                        <div class="fz-15 f-bold">{{ (cartItem.pvp).toFixed(2).replace('.', ',') }}€</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  <!-- modal body -->

                    <div class="modal-footer bg-blue">
                        <div class="d-flex w-100">
                            <div>
                                <p class="color-white mb-0">TOTAL</p>
                                <p class="color-white mt-0">Imp. Incluidos</p>
                            </div>
                            <div class="ml-auto color-white fz-24"><strong>{{carttotal.toFixed(2).replace('.', ',')}} €</strong></div>
                        </div>
                        <div class="mt-2 w-100">
                            <button class="btn btn-add-cart w-100" @click="sendCart">Ir a pagar</button>
                        </div>
                    </div> <!-- modal footer -->

                </div> <!-- modal content -->
            </div> <!-- modal dialog -->
        </div> <!-- modal -->
    </div>

</template>

<script>
    //import Modal from './Modal'

    export default {
        name: "CartDetail",
        props:['cart','carttotal','totalitems', 'modalid'],
        //components: { Modal },

        methods:{
            removeFromCart(item, all) {
                bus.$emit('remove-from-cart',item, all);
            },
            addToCart(item) {
                bus.$emit('added-to-cart',item);
            },
            close: function() {
                this.$emit('close')
            },
            discountedPrice(pvp, percentage_discount) {
                return (pvp - (pvp * (percentage_discount / 100))).toFixed(2).replace('.', ',');
            },
            sendCart() {
                bus.$emit('send-cart');
            }
        },
        created() {
            console.log('CartDetail Component mounted.')
        }
    }
</script>
