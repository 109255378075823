<template>
    <div v-bind:class="{ 'alert-success': isSuccess, 'alert-danger': isError }" class="alert spacing alert" role="alert" v-show="show">
        {{ body }}
    </div>
</template>

<script>
export default {
    props: ['message', 'type'],
    data() {
        return {
            show : false,
            style : '',
            body : ''
        }
    },
    computed: {
        isSuccess: function () {
            return this.style === 'success';
        },
        isError: function () {
            return this.style === 'error';
        },
    },
    created() {
        if(this.message) {
            this.flash(this.message, this.type)
        }
        window.events.$on('flash',(message, type) => this.flash(message, type))
    },
    methods: {
        flash(message, type) {
            this.show = true
            this.body = message
            this.style = type
            setTimeout(() => {
                this.hide()
            },3000)
        },
        hide() {
            this.show = false
        }
    }
}
</script>

<style>
.spacing {
    position: fixed;
    right: 25px;
    top: 25px;
    z-index: 99999;
}
</style>
